import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import '../../styles/components/Categories/FiltersCategories.css';
import '../../styles/components/Categories/ProductsCategories.css';
// import PriceFilter from '../Filters/PriceFilter';
import BrandFilter from '../Filters/BrandFilter';
import AcordionCategories from '../Categories/AcordionCategories';
import ProductCardSearch from './../ProductCardSearch';
import ProductCardLive from '../ProductCardLive';
const API = process.env.REACT_APP_CRM_BASE_URL + "/products-search";

const LayoutSearch = ({products}) => {

    function tieneTagVerde(producto) {
        return producto.tags.some(tag => tag.name.toUpperCase() === 'VERDE');
    }
    
    function filtrarStockEnStock(productos) {
        if (productos) {
            return productos.sort((a, b) => {
                const aEnStock = a.stock_status === 'instock';
                const bEnStock = b.stock_status === 'instock';
                const aTieneVerde = tieneTagVerde(a);
                const bTieneVerde = tieneTagVerde(b);
    
                if (aEnStock && !aTieneVerde && (!bEnStock || bTieneVerde)) {
                    return -1;
                }
                if (bEnStock && !bTieneVerde && (!aEnStock || aTieneVerde)) {
                    return 1;
                }
                if (aEnStock && aTieneVerde && !bEnStock) {
                    return -1;
                }
                if (bEnStock && bTieneVerde && !aEnStock) {
                    return 1;
                }
    
                // Agregando lógica para productos sin stock
                if (!aEnStock && !bEnStock) {
                    if (!aTieneVerde && bTieneVerde) {
                        return -1; // 'a' viene primero si ambos están sin stock y 'a' no tiene el tag VERDE
                    }
                    if (aTieneVerde && !bTieneVerde) {
                        return 1; // 'b' viene primero si ambos están sin stock y 'b' no tiene el tag VERDE
                    }
                }
                // Comparar por fecha de creación si los estados de stock y tag son iguales
                const aFecha = new Date(a.date_created);
                const bFecha = new Date(b.date_created);
                return bFecha - aFecha; // Orden descendente: el más reciente primero
            });
        }
        return []; // Si ambos tienen el mismo estado de stock y tag, se mantiene el orden actual
    }
    
    const productsStock = filtrarStockEnStock(products);
    const itemsPerPage = 12;
    const [sortedProducts, setSortedProducts] = useState(products);
    useEffect(() => {
        if (products) { 
            setSortedProducts(products);
        }
    }, [products]);
    // console.log(sortedProducts)
    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [modalFilterBy, setModalFilterBy] = useState(false);
    // Estado para el rango de precio
    // const [minPrice, setMinPrice] = useState(0);
    // const [maxPrice, setMaxPrice] = useState(200000);
    const [selectedBrands, setSelectedBrands] = useState({});



    const handleBrandChange = (brand) => { 
        // Primero, calculamos el nuevo estado de las marcas seleccionadas
        const updatedBrands = {
            ...selectedBrands,
            [brand]: !selectedBrands[brand]
        };
    
        // Luego, filtramos los productos con las marcas recién actualizadas
        const brandFiltered = productsStock?.filter(product => {
            const attribute = product.attributes.find(attr => attr.name === "Marca");
            if (!attribute) {
                return false; // O manejar de otra forma si el producto no tiene el atributo "Marca"
            }
        
            const productBrand = attribute.options[0];
            const anyBrandSelected = Object.values(updatedBrands).some(value => value);
        
            if (!anyBrandSelected) return true;
        
            return updatedBrands[productBrand];
        });
    
        // Finalmente, actualizamos el estado
        setSortedProducts(brandFiltered);
        setSelectedBrands(updatedBrands);
    };
    
    // const handleFilterChange = (selectedMinPrice, selectedMaxPrice) => {
    //     setMinPrice(selectedMinPrice);
    //     setMaxPrice(selectedMaxPrice);
    
    //     // Filtrar productos basándose en el rango de precio seleccionado
    //     const priceFiltered = productsStock.filter(product => 
    //         product.price >= selectedMinPrice && product.price <= selectedMaxPrice
    //     );
    
    //     setSortedProducts(priceFiltered);
    // };
    // const filterWithLatestState = (latestBrands, latestMinPrice, latestMaxPrice) => {
    //     // Filtrar por marca
    //     const brandFiltered = productsStock.filter(product => {
    //         const productBrand = product.attributes.find(attr => attr.name === "Marca").options[0];
    //         const anyBrandSelected = Object.values(latestBrands).some(value => value);
    
    //         if (!anyBrandSelected) return true;
    
    //         return latestBrands[productBrand];
    //     });
    
    //     // Filtrar por rango de precio
    //     const priceFiltered = brandFiltered.filter(product => {
    //         return product.price >= latestMinPrice && product.price <= latestMaxPrice;
    //     });
    
    //     return priceFiltered;
    // };
    const brands = [...new Set(productsStock?.map(product => product.attributes?.find(attr => attr.name === "Marca")?.options?.[0]).filter(Boolean))].sort();

     // Cantidad máxima de páginas a mostrar en la paginación
     const maxPageNumbersToShow = 5; 

     // Lógica para generar los números de página a mostrar en la paginación
     const getPageNumbersToShow = () => {
         const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
         const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);
 
         // Calculamos el rango de números de página a mostrar
         let startPage = Math.max(1, currentPage - halfMaxPageNumbersToShow);
         let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
 
         // Si estamos cerca del principio, ajustamos el final de la lista
         if (startPage === 1) {
             endPage = Math.min(maxPageNumbersToShow, totalPages);
         }
         // Si estamos cerca del final, ajustamos el inicio de la lista
         else if (endPage === totalPages) {
             startPage = Math.max(1, endPage - maxPageNumbersToShow + 1);
         }
 
         // Generamos los números de página a mostrar
         const pageNumbersToShow = [];
         for (let i = startPage; i <= endPage; i++) {
             pageNumbersToShow.push(i);
         }
 
         return pageNumbersToShow;
     };
 
     // Generamos los números de página a mostrar
     const pageNumbersToShow = getPageNumbersToShow();
 
     // Renderizamos los números de página
     const renderPageNumbers = pageNumbersToShow.map(number => (
         <button 
             key={number} 
             onClick={() => currentPage !== number && setCurrentPage(number)} // Solo permite clickear si no es la página actual
             className={currentPage === number ? "active" : ""}
             disabled={currentPage === number}
             style={{ cursor: currentPage === number ? "default" : "pointer" }} // Cambia el cursor si es la página actual
         >
             {number}
         </button>
     ));
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const searchParams = new URLSearchParams(window.location.search);
            const marca = searchParams.get('marca');

            if (marca) {
                const updatedBrands = { [marca]: true };
                setSelectedBrands(updatedBrands);

                const brandFiltered = productsStock.filter(product => {
                    const productBrand = product.attributes.find(attr => attr.name === "Marca").options[0];
                    return updatedBrands[productBrand];
                });

                setSortedProducts(brandFiltered);
            }
        }
    }, []);

    useEffect(() => {
        // console.log(sortedProducts)
        if (sortedProducts) {
            const indexOfLastItem = currentPage * itemsPerPage;
            const indexOfFirstItem = indexOfLastItem - itemsPerPage;
            const newCurrentItems = sortedProducts.slice(indexOfFirstItem, indexOfLastItem);
            setCurrentItems(newCurrentItems);
        }
    }, [currentPage, sortedProducts, itemsPerPage]);

    const handleOpenModalFilterBy = () => {
        setModalFilterBy(!modalFilterBy)
    }
    const sortByNewIngreso = () => {
        const sorted = [...sortedProducts].sort((a, b) => {
            const aEnStock = a.stock_status === 'instock';
            const bEnStock = b.stock_status === 'instock';

            if (aEnStock && !bEnStock) return -1;
            if (!aEnStock && bEnStock) return 1;

            return new Date(b.date_created) - new Date(a.date_created);
        });
        setSortedProducts(sorted);
        setModalFilterBy(false);
        setCurrentPage(1);
    };

    const sortByPriceDown = () => {
        const sorted = [...sortedProducts].sort((a, b) => {
            const aEnStock = a.stock_status === 'instock';
            const bEnStock = b.stock_status === 'instock';

            if (aEnStock && !bEnStock) return -1;
            if (!aEnStock && bEnStock) return 1;

            return a.price - b.price;
        });
        setSortedProducts(sorted);
        setModalFilterBy(false);
        setCurrentPage(1);
    };

    const sortByPriceUp = () => {
        const sorted = [...sortedProducts].sort((a, b) => {
            const aEnStock = a.stock_status === 'instock';
            const bEnStock = b.stock_status === 'instock';

            if (aEnStock && !bEnStock) return -1;
            if (!aEnStock && bEnStock) return 1;

            return b.price - a.price;
        });
        setSortedProducts(sorted);
        setModalFilterBy(false);
        setCurrentPage(1);
    };
    
    if (productsStock && productsStock.length > 0) {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(sortedProducts.length / itemsPerPage); i++) {
            pageNumbers.push(i);
        }
        let paginationItems;
        if (pageNumbers.length <= 5) {
          paginationItems = pageNumbers.map(number => (
            <button key={number} onClick={() => setCurrentPage(number)}>
              {number}
            </button>
          ));
        } else { 
          paginationItems = (
            <>
              {/* <div className='productsCategories__right--jumpPage-pageNumber pageActive' onClick={() => setCurrentPage(1)}>1</div> */}
    
              {currentPage > 4 && <div className='productsCategories__right--jumpPage-pageNumber'>...</div>}
              {currentPage > 1 && (
                 <div onClick={() => setCurrentPage(currentPage - 1)} class="productsCategories__right--jumpPage-pageNumber pageActive">
                    {currentPage - 1}
                </div>
              )}
              <div class="productsCategories__right--jumpPage-pageNumber pageDisabled" disabled>{currentPage}</div>
              {currentPage < pageNumbers.length - 1 && (
                <div onClick={() => setCurrentPage(currentPage + 1)} class="productsCategories__right--jumpPage-pageNumber pageActive">
                    {currentPage + 1}
                </div>
              )}
              {currentPage < pageNumbers.length - 2 && <div className='productsCategories__right--jumpPage-pageNumber'>...</div>}
              <div className='productsCategories__right--jumpPage-pageNumber pageActive' onClick={() => setCurrentPage(pageNumbers.length)}>
                {pageNumbers.length}
              </div>
            </>
          );
        }
        return (
            <div class="Content__productsCategories--container">
                <div class="Content__productsCategories--left">
                    
                    <AcordionCategories />
                    {/* <div class="Content__productsCategories--left-filterByPrice">
                        <div class="productsCategories__left--filterByPrice-title">
                            <h4>FILTRAR POR PRECIO</h4>
                        </div>
                        <PriceFilter min={minPrice} max={maxPrice} onFilterChange={handleFilterChange} />
                        
                    </div> */}
                    <div class="Content__productsCategories--left-filterByBrand">
                        <div class="productsCategories__left--filterByBrand-title">
                            <h4>FILTRAR POR MARCA</h4>
                        </div>
                        <div class="productsCategories__left--filterByBrand-brands">
                            <BrandFilter brands={brands} selectedBrands={selectedBrands} onBrandChange={handleBrandChange} />
                        </div>
                    </div>
                </div>
                <div class="Content__productsCategories--right">
                    <div className='Content__productsCategories--mobileFilters'>
                        <div className='Content__productsCategories--mobileFilters-filter'>
                            <span>
                                Filtros
                            </span>
                            <img src={require('../../images/mobileFilters.png')} alt=""/>
                        </div>
                        <div className='Content__productsCategories--mobileFilters-filter'>
                            <span>
                                Ordenar por
                            </span>
                            <img src={require('../../images/mobileOrder.png')} alt=""/>
                        </div>
                    </div>
                    <div class="Content__productsCategories--right-orderBy">
                        {/* <button class="filterButton">Filtros <i class="fas fa-filter"></i></button> */}
                        <button onClick={handleOpenModalFilterBy} class="orderByButton">Ordenar por <FontAwesomeIcon icon={faCaretDown}/></button>
                        {!!modalFilterBy && (
                            <div className='orderBy-options'>
                                <div onClick={sortByPriceUp}>Precio Mayor a Menor</div>
                                <hr/>
                                <div onClick={sortByPriceDown}>Precio Menor a Mayor</div>
                                {/* <hr/>
                                <div>Popularidad</div> */}
                                <hr/>
                                <div onClick={sortByNewIngreso}>Nuevos Ingresos</div>
                            </div>
                        )}
                    </div>
                    <div class="Content__productsCategories--right-productsContainer">
                        {currentItems.map((product) => {
                            return(
                                <ProductCardLive title="Añadir al Carrito" template="" theme={""} key={product.wordpress_id} product={product}/>
                            )
                        })}
                    </div>
                    
                    <div class="Content__productsCategories--right-jumpPage">
                        {/* Botón de página anterior */}
                        <button className='prev' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Anterior
                        </button>
                        {/* Números de página */}
                        {renderPageNumbers}
                        {/* Botón de página siguiente */}
                        <button className='next' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === pageNumbersToShow.length}>
                            Siguiente
                        </button>
                    </div>
                   {/* <Paginate products={products} itemsPerPage={itemsPerPage}/> */}
                </div>
            </div>
        );
    }
}

export default LayoutSearch;
