import React, { useEffect } from "react";

const GTMDataLayerPusher = ({ event, pagePath, pageTitle, additionalData }) => {
  try {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.length = 0;
      window.dataLayer.push({
        'event': event,
        'pagePath': pagePath,
        'pageTitle': pageTitle,
        ...additionalData
      });
      console.log("Data layer pushed");
    }
  } catch (error) {
    console.error('Error pushing to GTM dataLayer:', error);
  }
};

const TGMHeader = () => {
  <>
    {/* Google Tag Manager */}
    <script
      dangerouslySetInnerHTML={{
        __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TK4S4KM');
        `,
      }}
    />
    {/* End Google Tag Manager */}
  </>
}

const TGMBody = () => {
<>
    {/* Google Tag Manager (noscript) */}
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-TK4S4KM"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
    {/* End Google Tag Manager (noscript) */}
  </>
}

export {GTMDataLayerPusher, TGMBody, TGMHeader};