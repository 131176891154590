import React, {useEffect, useRef} from "react";
import GifIcon from "../images/giftIcon.webp";
import Logo from "../images/logo-blanco.png";
import '../styles/components/Footer.css';
import { Link } from 'gatsby';
import Instagram from '../images/instagram.png';
import Youtube from '../images/youtube.png';
import Twitter from '../images/gorjeo.png';
import Facebook from '../images/facebook.png';
import TikTok from '../images/tiktok.png';

function Footer () {
    // const menuElementRef = useRef(null);
    // const targetElementRef = useRef(null);
    // useEffect(() => {
    //     if (menuElementRef.current && targetElementRef.current) {
    //         // Obtener la posición 'left' del primer elemento
    //         let getPositionMenu = menuElementRef.current.getBoundingClientRect().left;

    //         // Aplicar esta posición al segundo elemento
    //         targetElementRef.current.style.left = getPositionMenu.toFixed(0) + 'px';
    //         console.log(getPositionMenu);
    //     }
    // }, []); // El array vacío [] significa que este efecto se ejecuta solo una vez, similar a componentDidMount
    return (
        <>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css" integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA==" crossorigin="anonymous" referrerpolicy="no-referrer" />
            <footer className="Footer">
                <div className="Footer_newsletter">
                    <div className="container">
                        <div className="Footer_newsletter--container">
                            <div className="Footer_newsletter--left">
                                <div className="Footer_newsletter--left-icon">
                                    <img src={GifIcon} alt="" />
                                </div>
                                <div className="Footer_newsletter--left-text">
                                    <h4>¡DEJANOS TU CORREO!</h4>
                                    <p>Y podrás participar todos los meses por premios increíbles.</p>
                                </div>
                            </div>
                            <div className="Footer_newsletter--input">
                                <input type="text" name="newsletter" id="newsletter" placeholder="Escribe tu correo aquí..."/>
                                <button>Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Footer__information">
                    <div className="container">
                        <div className="Footer__information--container">
                            <div className="Footer__information--logo">
                                <a href="/">
                                    <img src={Logo} alt=""/>
                                </a>
                                <p>Dust 2 es una tienda</p>
                                <h4>100% digital abierta las 24 hrs</h4>
                            </div>
                            <div className="Footer__information--links">
                                <h4>Información</h4>
                                <ul>
                                    {/* <li><a target="__blank" href="https://contenido.dust2.gg/">Blog</a></li>
                                    <li><Link to="/como-comprar">¿Cómo comprar?</Link></li>
                                    <li><Link to="/preguntas-frecuentes">Preguntas Frecuentes</Link></li> */}
                                    <li><a href="/contacto">Contacto</a></li>
                                </ul>
                            </div>
                            <div className="Footer__information--links">
                                <h4>Horario de Atención Chat</h4>
                                <p>Lunes a Viernes <br />
                                09:00 hrs a 17:00 hrs
                                </p>

                                <h4 style={{marginTop: 20}}>Síguenos</h4>
                                <div className="Header__topBar--socials">
                                    <div className="Header__topBar--socials-instagram">
                                        <a href="https://www.instagram.com/dust2gg/" target={"_blank"}><i class="fa-brands fa-instagram"></i></a>
                                    </div>
                                    <div className="Header__topBar--socials-youtube">
                                        <a href="https://www.youtube.com/@dust2gg" target={"_blank"}><i class="fa-brands fa-youtube"></i></a>
                                    </div>
                                    {/* <div className="Header__topBar--socials-twitter">
                                        <a href="https://twitter.com/Dust2Gg" target={"_blank"}><img src={Twitter} alt="" /></a>
                                    </div> */}
                                    <div className="Header__topBar--socials-facebook">
                                        <a href="https://www.facebook.com/dust2.gg" target={"_blank"}><i class="fa-brands fa-facebook-f"></i></a>
                                    </div>
                                    <div className="Header__topBar--socials-tiktok">
                                        <a href="https://www.tiktok.com/@dust2gg" target={"_blank"}><i class="fa-brands fa-tiktok"></i></a>
                                    </div>
                                    <div className="Header__topBar--socials-twitch">
                                        <a href="https://www.twitch.tv/dust2gg" target={"_blank"}><i class="fa-brands fa-twitch"></i></a>
                                    </div>
                                    <div className="Header__topBar--socials-twitch">
                                        <a href="https://chat.whatsapp.com/F7NQuBS3hIr6eel10jvj87" target={"_blank"}><i class="fa-brands fa-whatsapp"></i></a>
                                    </div>
                                    <div className="Header__topBar--socials-twitch">
                                        <a href="https://www.linkedin.com/company/dust2-gg/" target={"_blank"}><i class="fa-brands fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="Footer__information--followUs">
                                <div className="Footer__information--followUs-icon">
                                    <img src={require("../images/footer-twitch.webp")} alt="" />
                                    <h4>INTERACTUA CON NOSOTROS EN VIVO Y EN DIRECTO</h4>
                                </div>
                                <div className="Footer__information--followUs-socials">
                                    <ul>
                                        <li><a href="#"><img src={require("../images/facebook.webp")} alt="" /></a></li>
                                        <li><a href="#"><img src={require("../images/instagram.webp")} alt="" /></a></li>
                                        <li><a href="#"><img src={require("../images/tiktok.webp")} alt="" /></a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="Footer__bottom">
                    <div className="container">
                        <div className="Footer__bottom--container">
                            <div className="Footer__bottom--rights">
                                <p>© Copyright 2023 Todos los derechos reservados. - Dust2.gg <a href="/politica-de-privacidad">Política de Privacidad</a> - Legal</p>
                            </div>
                            {/* <div className="Footer__bottom--payments">
                                <div className="Footer__bottom--payments-payment">
                                    <img src={require("../images/mercadoPago.webp")} alt="" />
                                </div>
                                <div className="Footer__bottom--payments-payment">
                                    <img src={require("../images/footer-khipu.webp")} alt="" />
                                </div>
                                <div className="Footer__bottom--payments-payment">
                                    <img src={require("../images/footer-webpay.webp")} alt="" />
                                </div>
                                <div className="Footer__bottom--payments-payment">
                                    <img src={require("../images/footer-onePay.webp")} alt="" />
                                </div>
                                <div className="Footer__bottom--payments-payment">
                                    <img src={require("../images/footer-coinbase.webp")} alt="" />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
        
    )
}

export {Footer};