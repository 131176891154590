import React from "react";
import {MyContext} from "../context/AppContext";
import '../styles/components/Cart.css';
import '../styles/components/Header.css';
import FloatCart from './FloatCart';
import ModalLogin from './ModalLogin';
// import { Menu } from 'antd';
import Menu from './Menu';
import MobileMenu from "./MobileMenu";
import dusitoRegister from "../images/dusitoRegister1.webp";
import dusitoCongrats from "../images/DusitoCongrats.webp";
import dusitoStiker5 from "../images/DusitoSticker5.webp";
import dusitoClose from "../images/Icon-close.webp";
import dusitoPokemon from "../images/DUSITO_POKEMON.webp";
import Logo from '../images/logo-blanco.png';
import AvatarStart from '../images/avatar-start.webp';
import Heart from '../images/heart.webp';
import CartLogo from '../images/shopping-cart.webp';
import BurgerIcon from '../images/burgerIcon.webp';
// import { SearchBox, VoiceSearch  } from 'react-instantsearch-dom';
import useResetScroll from "../hooks/useResetScroll";
import { SearchInput } from './Search/SearchInput';
import {GTMDataLayerPusher} from './Analytics/TagManager';
import '../styles/components/Search.css';
function Header ({ updateSearchQuery  }) {
    useResetScroll();
    const getLocalStorageItem = (key) => {
        if (typeof localStorage !== 'undefined') {
            return localStorage.getItem(key);
        }
        return null;
    };
    const allWishlist = JSON.parse(getLocalStorageItem("wishlist"));
    // console.log(allWishlist);
    const dataProductsCart = getLocalStorageItem("products_cart");
   
    // Inicializar totalStock a 0 por defecto
    let totalStock = 0;

    // Verificar si dataProductsCart no es null
    if (dataProductsCart) {
        const productsArray = JSON.parse(dataProductsCart);
        // Usar reduce solo si productsArray es un array
        if (Array.isArray(productsArray)) {
            totalStock = productsArray.reduce((total, product) => total + product.quantity, 0);
        }
    }

    const loginAuth = JSON.parse(getLocalStorageItem("user_login"));
    const {state, cartModal, modalCart, modalRegister, loginModal, modalLogin, menuMobile, modalMenuMobile } = React.useContext(MyContext);
    const [searchQuery, setSearchQuery] = React.useState('');
    // console.log(searchQuery)
    const handleSearchChange = (e) => {
        updateSearchQuery(e);
        // setSearchQuery(e.target.value);
    };
    const closeModalRegister = () => {
        modalRegister(false);
        modalLogin(false);
        document.querySelector(".Content__Register").classList.remove("registerActive");
    }

    const openModalCart = () => {
        modalCart(true);

        const transformedItems = JSON.parse(getLocalStorageItem("products_cart"))?.map(item => ({
            item_name: item.name,
            item_id: item.sku,
            price: item.price.toString(),
            item_brand: item.brand,
            item_category: item.category, 
            quantity: item.quantity,
            tags: item.tags,
        }));
        
        const gtmData = {
            event: "view_cart",
            pagePath: "float-cart",
            pageTitle: "Modal del carrito",
            additionalData: {
                ecommerce: {
                    items: transformedItems
                }
            }
        };
        GTMDataLayerPusher(gtmData);
        // console.log(cartModal.payload);
    }
    const openModalLogin = () => {
        modalLogin(true);
        // console.log(loginModal.payload);
    }
     const onClick = (e) => {
        // console.log('click', e);
    };
    const ButtonText = ({
        isListening,
        isBrowserSupported,
      }) => (isListening ? '⏹' : <i class="fa-solid fa-microphone"></i>);
    return (
        <div id="Main__Header">
            {/* <div className="Header__infoBar">
                <span>COMPRA HOY ANTES DE LAS <strong>12HRS</strong> Y RECIBE HOY MISMO EN SANTIAGO (NO APLICA A PRODUCTOS CON DESPACHO DIFERIDO)</span>
            </div> */}
            <div className="Header__topBar">
                <div className="container">
                    <div className="Header__topBar--container">
                        <div className="Header__logo">
                            <a href={'/'} >
                                <img src={Logo} alt=""/>
                            </a >
                        </div>
                        {/* <div>
                            <input type="text"  placeholder="buscar"/>
                        </div> */}
                        {/* <Search /> */}
                        <div className="groupSearch">
                        {/* <SearchBox
                            className="search-bar"
                            translations={{ placeholder: 'Escribe para buscar...' }}
                        /> */}
                        <div>
                            <SearchInput query={searchQuery} onSearch={handleSearchChange} />
                        </div>
                        {/* <VoiceSearch buttonTextComponent={ButtonText}/> */}
                        </div>
                        <div className="Header__topBar--right">
                            <div className="Header__actions">
                                <div className="Header__actions--login" onClick={!loginAuth ? () => openModalLogin() : null}>
                                    <div className="Header__actions--login-image">
                                        {loginAuth && (
                                            <a href="/cuenta"><img style={{borderRadius: "25px"}} src={loginAuth.meta_data.avatar_url} alt="" /></a> 
                                        )}
                                        {!loginAuth && (
                                        <img src={AvatarStart} alt="" />
                                        )}
                                        
                                    </div>
                                    <div className="Header__actions--login-text">
                                        
                                        {loginAuth && (
                                            <>
                                            <a href="/cuenta"><p style={{textTransform: "uppercase"}}>{loginAuth.first_name}</p></a>
                                            </>
                                        )}
                                        {!loginAuth && (
                                            <>
                                            <p style={{textTransform: "uppercase"}}>iniciar sesión</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="Header__actions--likes">
                                    <a href="/lista-deseos">
                                        <img src={Heart} alt="" />
                                        <span className="counter">{allWishlist ? allWishlist.length : 0}</span>
                                    </a>
                                </div>
                                <div className="Header__actions--cart" onClick={() => openModalCart()}>
                                    <img src={CartLogo} alt="" />
                                    <span className="counter">{totalStock}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="Header">
                <div className="container">
                    <div className="Header__container">
                        <div class="Header__mobileMenu">
                            <img onClick={()=>{modalMenuMobile(true)}} src={BurgerIcon} alt=""/>
                            {!!menuMobile.payload && (
                            <>
                                <MobileMenu/>
                                <div onClick={()=>{modalMenuMobile(false)}} className='overlay-mobile'></div>
                            </>
                            )}
                        </div>
                        {/* <Menu
                            onClick={onClick}
                            style={{
                            width: "100%",
                            background: "#000",
                            color: "#fff",
                            fontSize: 16,
                            fontFamily: "Lato",
                            textTransform: "uppercase",
                            display: "flex",
                            justifyContent: "center",
                            
                            }}
                            mode="horizontal"
                            items={[
                                {
                                    label: "Gamer",
                                    key: "mundo_gamer",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    placement: "center",
                                    children: [
                                        {
                                            label: <MundoGamer />,
                                            key: "mega_menu",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        },
                                    ],
                                },
                                {
                                    label: "Componentes",
                                    key: "componentes_pc",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    children: [
                                        {
                                            label: <ComponentPc />,
                                            key: "mega_component_pc",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                {
                                    label: "Streaming",
                                    key: "streaming",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    placement: "center",
                                    children: [
                                        {
                                            label: <Streaming />,
                                            key: "mega_menu2",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                {
                                    label: "CONSOLAS",
                                    key: "videoGame",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    children: [
                                        {
                                            label: <VideoGame />,
                                            key: "video",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                {
                                    label: "ELECTRÓNICA",
                                    key: "electronica",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    children: [
                                        {
                                            label: <Electronica />,
                                            key: "video",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                {
                                    label: "OFICINA Y HOGAR",
                                    key: "oficina",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    children: [
                                        {
                                            label: <Oficina />,
                                            key: "video",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                {
                                    label: "COMPUTADORES",
                                    key: "setups",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    children: [
                                        {
                                            label: <Setup />,
                                            key: "setup2",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                {
                                    label: "PASATIEMPO",
                                    key: "pasatiempo",
                                    icon: <AppstoreOutlined style={{fontSize: "16px"}}/>,
                                    children: [
                                        {
                                            label: <Pasatiempo />,
                                            key: "pasatiempo2",
                                            style: {
                                                height: "fit-content",
                                                background: "#fff",
                                                width: "1300px",
                                            },
                                        }
                                    ]
                                },
                                
                            ]}
                        /> */}
                        <Menu />
                        <a href={'/'} className="logoMobile">
                            <img src={Logo} alt=""/>
                        </a>
                        <div className="Header__topBar--right Header__topBar--right-mobile">
                            <div className="Header__actions">
                                <div className="Header__actions--login" onClick={!loginAuth ? () => openModalLogin() : null}>
                                    <div className="Header__actions--login-image">
                                        {loginAuth && (
                                            <a href="/cuenta"><img style={{borderRadius: "25px"}} src={loginAuth.meta_data.avatar_url} alt="" /></a> 
                                        )}
                                        {!loginAuth && (
                                        <img src={AvatarStart} alt="" />
                                        )}
                                        
                                    </div>
                                    <div className="Header__actions--login-text">
                                        
                                        {loginAuth && (
                                            <>
                                            <a href="/cuenta"><p style={{textTransform: "uppercase"}}>{loginAuth.first_name}</p></a>
                                            </>
                                        )}
                                        {!loginAuth && (
                                            <>
                                            <p style={{textTransform: "uppercase"}}>iniciar sesión</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className="Header__actions--cart" onClick={() => openModalCart()}>
                                    <img src={CartLogo} alt="" />
                                    <span className="counter">{dataProductsCart ? JSON.parse(dataProductsCart).length : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="Header__mobileSearch">
                <div className="container">
                    <div className="Header__mobileSearch--container">
                        <div className="groupSearch">
                            <SearchInput query={searchQuery} onSearch={handleSearchChange} />
                        {/* <SearchBox
                            className="search-bar"
                            translations={{ placeholder: 'Escribe para buscar...' }}
                        />
                        <VoiceSearch buttonTextComponent={ButtonText}/> */}
                        </div>
                    </div>
                </div>
            </div>
            {!!cartModal.payload && (
                <FloatCart cartModal={cartModal.payload} />
            )}
            {!!loginModal.payload && (
                <ModalLogin status={loginModal.payload} />
            )}
            <div class="Content__Register">
                <div class="Content__Register--container">
                    <div class="Content__Register--top">
                        <div class="Content__Register--top-icon desktopGato gatoActive">
                            <img src={dusitoRegister} alt="" />
                        </div>
                        <div class="Content__Register--top-icon mobileGato">
                            <img src={dusitoCongrats} alt="" />
                        </div>
                        <div class="Content__Register--top-icon registerGato1">
                            <img src={dusitoRegister} alt="" />
                        </div>
                        <div class="Content__Register--top-icon dusitoSticker">
                            <img src={dusitoStiker5} alt="" />
                        </div>
                        <div class="Content__Register--top-icon dusitoPokemon">
                            <img src={dusitoPokemon} alt="" />
                        </div>
                        <div onClick={closeModalRegister} class="Content__Register--top-close">
                            <img src={dusitoClose} alt="" />
                        </div>
                    </div>
                    <div id="finalMessageBody" class="Content__Register--body bodyActive">
                        <div class="Content__Register--body-title">
                            <h2 style={{fontSize: '1.6vw', letterSpacing: '4px', marginBottom: '1vw'}}>Dusito te da la bienvenida al universo Dust2!!</h2>
                            <p>Gracias por volver con nosotros.</p>
                        </div>
                    </div>
                    <div class="Content__Register--bottom-container">
                        <div class="Content__Register--body-button">
                            <button id='' onClick={()=>{closeModalRegister()}} className='buttonActive'>Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export { Header };