import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCaretDown, faTrash } from '@fortawesome/free-solid-svg-icons';
export default function AcordionCategories() {
    const [activePanel, setActivePanel] = React.useState(-1);

    const handleAccordionClick = (index) => {
        if (activePanel === index) {
          setActivePanel(-1);
        } else {
          setActivePanel(index);
        }
      };
  return (
    <div class="Content__productsCategories--left-categoriesList">
        <div class="productsCategories__left--categoriesList-top">
            <h2>
                {/* <i class="fas fa-plus"></i>  */}
                <span>CATEGORÍAS</span></h2>
        </div>
        <div class="Content__foundProducts--left-categories">
                <div class="foundProducts__left--categories-category">
                    <button 
                        className={`accordion ${activePanel === 0 ? 'active' : ''}`}
                        onClick={() => handleAccordionClick(0)}>
                        MUNDO GAMER <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 0 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/teclados-gamer"}>TECLADOS GAMER</a></li>
                        <li><a href={"/categoria-producto/mouse-gamer"}>MOUSE GAMER</a></li>
                        <li><a href={"/categoria-producto/audifonos-gamer"}>AUDIFONOS GAMER</a></li>
                        <li><a href={"/categoria-producto/mousepad-gamer"}>MOUSEPAD</a></li>
                        <li><a href={"/categoria-producto/escritorios-gamer"}>ESCRITORIOS GAMER</a></li>
                        <li><a href={"/categoria-producto/28"}>LENTES GAMING</a></li>
                        <li><a href={"/categoria-producto/sillas-gamer"}>SILLAS GAMER</a></li>
                        <li><a href={"/categoria-producto/kits-gamer"}>KITS GAMER</a></li>
                        <li><a href={"/categoria-producto/parlantes-gamer"}>PARLANTES</a></li>
                        <li><a href={"/categoria-producto/soporte-de-audifonos"}>SOPORTE DE AUDIFONOS</a></li>
                        <li><a href={"/categoria-producto/controles-y-volantes"}>CONTROLES Y VOLANTES</a></li>
                        <li><a href={"/categoria-producto/iluminacion-rgb"}>ILUMINACIÓN RGB</a></li>
                        <li><a href={"/categoria-producto/otros-accesorios"}>OTROS ACCESORIOS</a></li>
                        <li><a href={"/categoria-producto/24-a-27-pulgadas"}>24 A 27 PULGADAS</a></li>
                        <li><a href={"/categoria-producto/29-superior"}>29 O SUPERIOR</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                        className={`accordion ${activePanel === 6 ? 'active' : ''}`}
                        onClick={() => handleAccordionClick(6)}>
                        COMPONENTES <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 6 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/procesadores"}>PROCESADORES</a></li>
                        <li><a href={"/categoria-producto/tarjetas-de-video"}>TARJETAS DE VIDEO</a></li>
                        <li><a href={"/categoria-producto/placas-madres"}>PLACAS MADRE</a></li>
                        <li><a href={"/categoria-producto/fuentes-de-poder"}>FUENTES DE PODER</a></li>
                        <li><a href={"/categoria-producto/gabinetes"}>GABINETES</a></li>
                        <li><a href={"/categoria-producto/memorias-ram"}>MEMORIAS RAM</a></li>
                        <li><a href={"/categoria-producto/pasta-termica"}>PASTA TÉRMICA</a></li>
                        <li><a href={"/categoria-producto/refrigeracion-liquida"}>REFRIGERACIÓN LIQUIDA</a></li>
                        <li><a href={"/categoria-producto/fans-y-controladores"}>FANS Y CONTROLADORES</a></li>
                        <li><a href={"/categoria-producto/cooler-para-cpu"}>COOLER PARA CPU</a></li>
                        <li><a href={"/categoria-producto/accesorios"}>ACCESORIOS COMPONENTES</a></li>
                        <li><a href={"/categoria-producto/almacenamiento"}>ALMACENAMIENTO</a></li>
                        <li><a href={"/categoria-producto/discos-m-2"}>DISCOS M.2</a></li>
                        <li><a href={"/categoria-producto/ssd-y-discos-duros"}>SSD Y DISCO DURO</a></li>
                        <li><a href={"/categoria-producto/discos-y-ssd-externos"}>DISCOS Y SSD EXTERNOS</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                        className={`accordion ${activePanel === 1 ? 'active' : ''}`}
                        onClick={() => handleAccordionClick(1)}>
                        STREAMING <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 1 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/webcams-streaming"}>WEBCAMS</a></li>
                        <li><a href={"/categoria-producto/microfonos"}>MICROFONOS</a></li>
                        <li><a href={"/categoria-producto/iluminacion"}>ILUMINACIÓN</a></li>
                        <li><a href={"/categoria-producto/capturadoras"}>CAPTURADORAS</a></li>
                        <li><a href={"/categoria-producto/stream-decks"}>STREAM DECKS</a></li>
                        <li><a href={"/categoria-producto/otros-accesorios-streaming"}>OTROS ACCESORIOS</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                        className={`accordion ${activePanel === 2 ? 'active' : ''}`}
                        onClick={() => handleAccordionClick(2)}>
                        CONSOLAS <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 2 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/nintendo"}>NINTENDO</a></li>
                        <li><a href={"/categoria-producto/juegos"}>JUEGOS</a></li>
                        <li><a href={"/categoria-producto/controles-y-cargadores"}>CONTROLES Y CARGADORES</a></li>
                        <li><a href={"/categoria-producto/fundas-y-estuches"}>FUNDAS Y ESTUCHES</a></li>
                        <li><a href={"/categoria-producto/playstation-5"}>PLAYSTATION 5</a></li>
                        <li><a href={"/categoria-producto/audifonos-ps5"}>AUDIFONOS PS5</a></li>
                        <li><a href={"/categoria-producto/controles-y-cargadores-ps5"}>CONTROLES Y CARGADORES</a></li>
                        <li><a href={"/categoria-producto/xbox"}>XBOX</a></li>
                        <li><a href={"/categoria-producto/audifonos-xbox/"}>AUDIFONOS XBOX</a></li>
                        <li><a href={"/categoria-producto/controles-y-cargadores-xbox/"}>CONTROLES Y CARGADORES XBOX</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                        className={`accordion ${activePanel === 3 ? 'active' : ''}`}
                        onClick={() => handleAccordionClick(3)}>
                        ELECTRONICA <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 3 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/impresoras"}>IMPRESORAS</a></li>
                        <li><a href={"/categoria-producto/routers-y-switches"}>ROUTERS Y SWITCHES</a></li>
                        <li><a href={"/categoria-producto/antenas-tv"}>ANTENAS TV</a></li>
                        <li><a href={"/categoria-producto/soportes-tv"}>SOPORTES TV</a></li>
                        <li><a href={"/categoria-producto/respaldo-energia"}>UPS RESPALDO ENERGIA</a></li>
                        <li><a href={"/categoria-producto/asistentes-virtuales"}>ASISTENTES VIRTUALES</a></li>
                        <li><a href={"/categoria-producto/iluminacion-rgb-electronica"}>ILUMINACIÓN RGB</a></li>
                        <li><a href={"/categoria-producto/enchufes-inteligentes"}>ENCHUFES INTELIGENTES</a></li>
                        <li><a href={"/categoria-producto/smartband"}>SMARTBAND</a></li>
                        <li><a href={"/categoria-producto/atriles-y-tripodes"}>ATRILES Y TRIPODES</a></li>
                        <li><a href={"/categoria-producto/tarjetas-de-memoria-electronica"}>TARJETAS DE MEMORIA</a></li>
                        <li><a href={"categoria-producto/cables-y-adaptadores"}>CABLES Y ADAPTADORES</a></li>
                        <li><a href={"/categoria-producto/pendrives"}>PENDRIVES</a></li>
                        <li><a href={"/categoria-producto/accesorios-tablets"}>ACCESORIOS TABLETS</a></li>
                        <li><a href={"/categoria-producto/notebooks"}>NOTEBOOKS</a></li>
                        <li><a href={"/categoria-producto/equipos"}>EQUIPOS</a></li>
                        <li><a href={"/categoria-producto/memorias-ram-notebooks"}>MEMORIAS RAM</a></li>
                        <li><a href={"/categoria-producto/bases-refrigeracion"}>BASES REFRIGERACIÓN</a></li>
                        <li><a href={"/categoria-producto/bolsos-y-maletines"}>BOLSOS Y MALETINES</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                    className={`accordion ${activePanel === 4 ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(4)}>
                        OFICINA Y HOGAR <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 4 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/teclados-perifericos"}>TECLADOS</a></li>
                        <li><a href={"/categoria-producto/mouse-perifericos"}>MOUSE</a></li>
                        <li><a href={"/categoria-producto/webcam"}>WEBCAM</a></li>
                        <li><a href={"/categoria-producto/audifonos-audio"}>AUDIFONOS</a></li>
                        <li><a href={"/categoria-producto/tabletas-digitalizadoras"}>TABLETAS DIGITALIZADORAS</a></li>
                        <li><a href={"/categoria-producto/hubs-usb"}>HUBS USB</a></li>
                        <li><a href={"/categoria-producto/apoyos-lumbares"}>APOYOS LUMBARES</a></li>
                        <li><a href={"/categoria-producto/soporte-monitores"}>SOPORTE MONITORES</a></li>
                        <li><a href={"/categoria-producto/parlantes-audio"}>PARLANTES</a></li>
                        <li><a href={"/categoria-producto/combo-teclado-y-mouse"}>COMBOS TECLADO Y MOUSE</a></li>
                        <li><a href={"/categoria-producto/monitores-oficina"}>MONITORES OFICINA</a></li>
                        <li><a href={"/categoria-producto/24-a-27-pulgadas-oficina"}>24 A 27 PULGADAS</a></li>
                        <li><a href={"/categoria-producto/27-a-32-pulgadas-oficina"}>27 A 32 PULGADAS</a></li>
                        <li><a href={"/categoria-producto/29-o-superior-oficina"}>29 O SUPERIOR</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                    className={`accordion ${activePanel === 5 ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(5)}>
                        COMPUTADORES <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 5 ? 'panelActive' : ''}`}>
                        <li><a href={"/categoria-producto/setups"}>DESKTOPS</a></li>
                        <li><a href={"/categoria-producto/equipos"}>NOTEBOOKS</a></li>
                        <li><a href={"/categoria-producto/aio"}>ALL IN ONE</a></li>
                    </ul>
                </div>
                <div class="foundProducts__left--categories-category">
                    <button 
                    className={`accordion ${activePanel === 7 ? 'active' : ''}`}
                    onClick={() => handleAccordionClick(7)}>
                        PASATIEMPO <span><FontAwesomeIcon style={{height: "15px"}} icon={faPlus} color="#b9b9b9"/></span></button>
                    <ul className={`panel ${activePanel === 7 ? 'panelActive' : ''}`}>
                        <li><a href={"/store/marca/lego/"}>LEGO</a></li>
                    </ul>
                </div>
                {/* <div class="foundProducts__left--categories-category">
                    <button><a href={"/categoria-producto/setups"}>SETUPS</a></button>
                </div> */}
        </div>
    </div>
  )
}
