import React, {useState} from 'react';
import {MyContext} from '../context/AppContext';
import '../styles/components/ProductCard.css';
import CashIcon from '../images/cash-icon.webp';
import CardIcon from '../images/card-icon.webp';
import AddWishlist from './Whishlist/AddWishlist';
import OutStockIcon from "../images/out-of-stock.png"

const ProductCardLive = ({product, theme, title, template}) => {
    const {addToCart, modalCart} = React.useContext(MyContext);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingCart, setLoadingCart] = useState(false);
    const [cartText, setCartText] = useState(title);
    const [buttonStyle, setButtonStyle] = useState({height: "30px"});
    const handleClick = async (item) => {
        setLoadingCart(true);  // Activa el loader
        // Realizar solicitud a la API para obtener stock y precio actualizados
        const response = await fetch(`https://crm.dust2.gg/api/crm/stock-price/${item.wordpress_id}`);
        const data = await response.json();
    
        // Verifica si hay stock disponible
        if (data.stock_quantity > 0) {
            const brandAttribute = item.attributes.find(attr => attr.name === "Marca");
            const brandValue = brandAttribute && brandAttribute.options.length > 0 ? brandAttribute.options[0] : "";
    
            const productCart = {
                wordpress_id: item.wordpress_id,
                sku: item.sku,
                name: item.name,
                slug: item.slug,
                price: data.price, // Actualizar el precio según la API
                dimensions: item.dimensions,
                weight: item.weight,
                image: item.images[0]?.src,
                description: item.description,
                quantity: 1, // Ajustar la cantidad según la API
                tags: item.tags,
                stock_quantity: data.stock_quantity,
                category: item.categories ? item.categories[0].name : "",
                category_id : item.categories ? item.categories[0].woo_id : "",
                brand: brandValue
            }
            addToCart(productCart);
            // modalCart(true);
            setLoadingCart(false);  // Activa el loader
            setCartText("Añadido al carrito");
            setButtonStyle({height: "30px", backgroundColor: "#009688" });

            if (template != "cart") {
                modalCart(true);
            }
            
        } else {
            setLoadingCart(false);  // Activa el loader
            setCartText("Agotado");
            setButtonStyle({height: "30px", backgroundColor: "#871111d1", pointerEvents: "none"});
        }

        
    }
    const discountPrice = Number(product.price) - (Number(product.price) * 0.07).toFixed(0);
    let envioRapidoTag;
    if (product && Array.isArray(product.tags)) {
      envioRapidoTag = product.tags.find(item => item.slug === "envio-rapido");
    }

    let flashOffertsTag;
    if (product && Array.isArray(product.tags)) {
        flashOffertsTag = product.tags.find(item => item.slug === "oferta-flash");
    }

    let fastShippingTag;
    if (product && Array.isArray(product.tags)) {
        fastShippingTag = product.tags.find(item => item.slug === "verde");
    }

    let freeShippingTag;
    if (product && Array.isArray(product.tags)) {
        freeShippingTag = product.tags.find(item => item.slug === "envio-gratis-en-santiago");
    }
    let nzxtpreventa;
    if (product && Array.isArray(product.tags)) {
        nzxtpreventa = product.tags.find(item => item.slug === "nzxtpreventa");
    }
    // console.log(product);
    return (
        <div className={`productCard ${template}`}>
            <div class="top-black-product">
                {product.attributes && (
                <span>
                    {product.attributes[0]?.options}
                </span>
                )}
                <span>{product.stock_quantity > 10 ? "+10 Unds" : `${product.stock_quantity} Unds`}  </span>
            </div>
            <div className={theme == "dark" ? "productCard-white" : ""}>
                <div className="productCard__image">
                    <div class="productCard__whislist">
                        <AddWishlist product={product} color={"#f29f05"} fontSize={"28px"}/>
                    </div>
                    {product.stock_status == "outofstock" && (
                    <div className='productCard__image--outStock'>
                        <span>Sin Stock</span>
                    </div>
                    )}
                    <a href={`/producto/${product.slug}`}><img src={product.product_card_image} alt="#" /></a>
                    <div className='flexTag'>
                        {envioRapidoTag && (
                        <div className='tagFlag__envio'>
                            <span>{envioRapidoTag.name}</span>
                        </div>
                        )}
                        {freeShippingTag && (
                        <div className='tagFlag__envio freeShippingTag'>
                            <span>{freeShippingTag.name}</span>
                        </div>
                        )}
                        {!fastShippingTag && !nzxtpreventa &&(
                        <div className='tagFlag__envio fastShippingTag'>
                            <span>Despacho Rapido</span>
                        </div>
                        )}
                        {nzxtpreventa && (
                        <div className='tagFlag__envio fastShippingTag' style={{backgroundColor: "#4CAF50"}}>
                            <span>PREVENTA</span>
                        </div>
                        )}
                        {flashOffertsTag && (
                        <div className='tagFlag__envio flashOffertsTag'>
                            <span>{flashOffertsTag.name}</span>
                        </div>
                        )}
                    </div>
                   
                </div>
                <div className="productCard__details">
                    
                    <div className="productCard__details--name">
                        <a href={`/producto/${product.slug}`}><h4>{product.name}</h4></a>
                    </div>
                    <div className="productCard__details--category">
                        {product.categories && (
                            <p>{product.categories[0]?.name}</p>
                        )

                        }
                    </div>
                    <div className="productCard__details--prices">
                        <div className="productCard__details--prices-price">
                            <p>Transf:</p>
                            <span>
                                ${new Intl.NumberFormat('de-DE').format(Number(product.price).toFixed(0))}
                            </span>
                            <img src={CashIcon} alt="#" />
                        </div>
                        <div className="productCard__details--prices-price" style={{display: "flex"}}>
                            <p>Tarjeta:</p>
                            <span>
                            ${new Intl.NumberFormat('de-DE').format(Number(product.price / 0.93).toFixed(0))}
                            </span>
                                <img src={CardIcon} alt="#" />
                        </div>
                        <div className='actions-test' style={{paddingTop: "5px", paddingBottom: "5px", width: "100%"}}>
                            {product.stock_quantity <= 0 ? (
                                <>
                                    <div className="singleProduct__right--addToCart-addButton out-of-stock" style={{height: "2.5vw"}}>
                                        <button style={{ background: "#871111d1" }} onClick={() => { handleClick(product) }}>
                                            <span>Agotado</span> 
                                            <img src={OutStockIcon} alt="" />
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <button style={buttonStyle} onClick={() => handleClick(product)}> 
                                    <span>{loadingCart ? 'Agregando...' : cartText}</span> 
                                </button>
                            )}
                                   
                            {/* <div className="productCard__details--rating">
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCardLive;
