import React, {useState, useEffect} from 'react'
import { Footer } from './Footer'
import '../App.css';
import '../styles/Responsive.css';
import { Header } from './Header';
import WhatsappChat from './WhatsappChat';
import SearchComponent from './Search/SearchComponente';
import { TagManager, TGMHeader, TGMBody } from './Analytics/TagManager';
function Layout({children}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [pageTitle, setPageTitle] = useState('Titulo Actual');
  // console.log(searchQuery)
  
  return (
    <> 
      <script src="https://kit.fontawesome.com/2b35d95dde.js" crossorigin="anonymous"></script>
      {/* <TGMHeader /> */}
      <Header updateSearchQuery={setSearchQuery} />
      {searchQuery && <SearchComponent searchQuery={searchQuery} />}
      {!searchQuery && children}
      <WhatsappChat />
      {/* <TGMBody /> */}
      <Footer />
    </>
  )
}

export default Layout