import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SearchResults } from './SearchResults';
import {useStaticQuery, graphql } from "gatsby"

const SearchComponent = ({ searchQuery = '' }) => { // Proporciona un valor por defecto para searchQuery
  const data = useStaticQuery(graphql`
  query {
    allProduct(
      sort: {date_created: DESC}
    ) {
      nodes {
        id
        wordpress_id
        sku
        name
        slug
        price
        description
        stock_status
        stock_quantity
        categories {
          id
          woo_id
          name
          slug
        }
        images {
          src
        }
        product_card_image
        dimensions {
          width,
          height,
          length
        }
        attributes {
          name
          slug
          options
        }
        tags {
          id
          name
          slug
        }
        date_created
      }
    }
  }
  `);
  const [results, setResults] = useState([]);
  // const [allProducts, setAllProducts] = useState();
  const allProducts = data.allProduct.nodes;
  // console.log(allProducts)
  // useEffect(() => {
  //   axios.get(`https://crmdust2.com/api/index/woo/products`)
  //     .then(response => {
  //       setAllProducts(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error al obtener los productos:', error);
  //     });
  // }, []);

  useEffect(() => {
    // Verifica si searchQuery es válido antes de usar toLowerCase
    if (searchQuery) {
      const searchQueryWords = searchQuery.toLowerCase().trim().split(' ').filter(word => word);
      if (searchQueryWords.length) {
        const filteredResults = allProducts.filter(product => {
          const productNameWords = product.name.toLowerCase().split(' ').filter(word => word);
          const productSKU = product.sku?.toLowerCase();

          return searchQueryWords.every(queryWord => 
            productNameWords.some(productWord => productWord.includes(queryWord)) ||
            productSKU?.includes(queryWord)
          );
        });
        setResults(filteredResults);
      } else {
        setResults([]);
      }
    } else {
      setResults([]);
    }
  }, [searchQuery, allProducts]);

  return <SearchResults results={results} />;
};

export default SearchComponent;
