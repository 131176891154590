import React, {useState} from 'react';
// import { Link } from 'gatsby';
import {MyContext} from '../context/AppContext';
import '../styles/components/ProductCard.css';
import CashIcon from '../images/cash-icon.webp';
import CardIcon from '../images/card-icon.webp';
import AddWishlist from './Whishlist/AddWishlist';

const ProductCard = ({product, theme}) => {
    const {addToCart, modalCart} = React.useContext(MyContext);
    const [liveProduct, setLiveProduct] = useState(product);
    const [isLoading, setIsLoading] = useState(false);

    const fetchProductData = async (productId) => {
        try {
            setIsLoading(true);  // Inicia la carga
            const response = await fetch(`${process.env.REACT_APP_WOO_BASE_URL}/products/${productId}?${process.env.REACT_APP_WOO_CS}&${process.env.REACT_APP_WOO_CK}`);
            const data = await response.json();
    
            // Devuelve los datos actualizados del producto
            return {
                stock_quantity: data.stock_quantity,
                price: data.price,
                // Añade aquí otros campos que quieras actualizar
            };
        } catch (error) {
            console.error("Error fetching product data:", error);
            return null;
        } finally {
            setIsLoading(false);  // Finaliza la carga
        }
    };
    // React.useEffect(() => {
    //     fetchProductData();
    // }, []);
    // const handleClick = async (item) => {
    //     const updatedData = await fetchProductData(item.wordpress_id);
    
    //     if (updatedData) {
    //         const productCart = {
    //             wordpress_id: item.wordpress_id,
    //             sku: item.sku,
    //             name: item.name,
    //             slug: item.slug,
    //             price: updatedData.price, // Usa el precio actualizado
    //             dimensions: item.dimensions,
    //             weight: item.weight,
    //             image: item.images[0]?.src,
    //             description: item.description,
    //             quantity: 1,
    //             tags: item.tags,
    //             stock_quantity: updatedData.stock_quantity // Usa la cantidad de stock actualizada
    //         };
    
    //         // console.log(productCart);
    //         addToCart(productCart);
    //         modalCart(true);
    //     } else {
    //         // Maneja el caso en que la actualización de datos falle
    //         console.log("No se pudo obtener la información actualizada del producto.");
    //     }
    // };
    const discountPrice = Number(product?.price) - (Number(product?.price) * 0.07).toFixed(0);
    let envioRapidoTag;
    if (product && Array.isArray(product.tags)) {
      envioRapidoTag = product.tags.find(item => item.slug === "envio-rapido");
    }

    let flashOffertsTag;
    if (product && Array.isArray(product.tags)) {
        flashOffertsTag = product.tags.find(item => item.slug === "oferta-flash");
    }

    let fastShippingTag;
    if (product && Array.isArray(product.tags)) {
        fastShippingTag = product.tags.find(item => item.slug === "verde");
    }

    let freeShippingTag;
    if (product && Array.isArray(product.tags)) {
        freeShippingTag = product.tags.find(item => item.slug === "envio-gratis-en-santiago");
    }
    // console.log(product)
    // console.log(product);
    return (
        <div className="productCard">
            <div class="top-black-product">
                <span>{product.attributes[0]?.options}</span>
                <span>{product.stock_quantity > 10 ? "+10 Unds" : `${product.stock_quantity} Unds`}  </span>
            </div>
            <div className={theme == "dark" ? "productCard-white" : ""}>
                <div className="productCard__image">
                    <div class="productCard__whislist">
                        {/* <AddWishlist product={product} color={"#b2b2b2"} fontSize={"28px"}/> */}
                    </div>
                    {product.stock_status == "outofstock" && (
                    <div className='productCard__image--outStock'>
                        <span>Sin Stock</span>
                    </div>
                    )}
                    <a href={`/producto/${product.slug}`}><img src={product?.product_card_image?.replace("https://dust2.gg", "https://backend.dust2.gg")} alt="#" /></a>
                    <div className='flexTag'>
                        {envioRapidoTag && (
                        <div className='tagFlag__envio'>
                            <span>{envioRapidoTag.name}</span>
                        </div>
                        )}
                        {freeShippingTag && (
                        <div className='tagFlag__envio freeShippingTag'>
                            <span>{freeShippingTag.name}</span>
                        </div>
                        )}
                        {/* {!fastShippingTag && (
                        <div className='tagFlag__envio fastShippingTag'>
                            <span>Despacho Rapido</span>
                        </div>
                        )} */}
                        {flashOffertsTag && (
                        <div className='tagFlag__envio flashOffertsTag'>
                            <span>{flashOffertsTag.name}</span>
                        </div>
                        )}
                    </div>
                    
                   
                </div>
                <div className="productCard__details">
                    
                    <div style={{marginBottom: "3px"}} className="productCard__details--name">
                        <a href={`/producto/${product.slug}`}><h4>{product.name}</h4></a>
                    </div>
                    <div className="productCard__details--category">
                        <p>{product.categories ? product.categories[0]?.name : ""}</p>
                    </div>
                    <div style={{marginTop: "-0.4vw"}} className="productCard__details--category">
                        <p>{product.sku}</p>
                    </div>
                    <div className="productCard__details--prices">
                        <div className="productCard__details--prices-price">
                            <p>Transf:</p>
                            <span>
                                   ${new Intl.NumberFormat('de-DE').format(Number(discountPrice).toFixed(0))}
                            </span>
                                {/* <img src={CashIcon} alt="#" /> */}
                        </div>
                        <div className="productCard__details--prices-price" style={{display: "flex"}}>
                            <p>Tarjeta:</p>
                            <span>
                                ${new Intl.NumberFormat('de-DE').format(Number(product.price).toFixed(0))}
                            </span>
                                {/* <img src={CardIcon} alt="#" /> */}
                        </div>
                        <div className='actions-test' style={{paddingTop: "5px", paddingBottom: "5px", width: "100%"}}>
                            {/* <button style={{height: "30px"}} onClick={() => handleClick(product)}>Agregar al carrito</button> */}
                            {/* <div className="productCard__details--rating">
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                                <img src={require("../images/star.webp")} alt="#" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
