import React from 'react'
import whatsappIcon from '../images/whatsapp.png';

function WhatsappChat() {

  const container = {
    display: 'flex',
    position: 'fixed',
    bottom: '15px',
    right: '20px',
    maxWidth: '64px',
    boxShadow: '1px 2px 8px #0000006e',
    borderRadius: '50%',
    width: "64px",
    height: "64px",
    zIndex: "9999"
  }
  return (
    <div className='chatWSP' style={container}>
      <a target='__blank' href="https://api.whatsapp.com/send/?phone=56997937643&text=Hola%20Dusito,%20necesito%20ayuda%C2%A0%F0%9F%86%98&type=phone_number&app_absent=0">
        <img  src={whatsappIcon} alt="" />
      </a>
    </div>
  )
}

export default WhatsappChat