import React from 'react';
import '../styles/components/MobileMenu.css';
import {MyContext} from "../context/AppContext";
import { Link } from 'gatsby';

const MobileMenu = () => {
    const {menuMobile, modalMenuMobile } = React.useContext(MyContext);
    const menuClick = (bodyName, e) => {
        document.querySelector('.bodyActive2').classList.remove('bodyActive2');
        document.querySelector(`[BodyName=${bodyName}]`).classList.add('bodyActive');
        document.querySelector(".Content__mobileMenu--currentLink i").style.display = 'block';
        console.log(e.target.innerText);
        document.querySelector(".Content__mobileMenu--currentLink h4").innerText = e.target.innerText.toUpperCase();
    }
    const goMenuBack = () => {
        document.querySelectorAll(".bodyActive").forEach((bodyClear2)=>{
            bodyClear2.classList.remove("bodyActive");
        })
        document.querySelector(`[linkType="linkCategories"]`).classList.add("bodyActive2")
        document.querySelector(".Content__mobileMenu--currentLink h4").innerText = "CATEGORIAS"
        document.querySelector(".Content__mobileMenu--currentLink i").style.display = "none"
    }
    const handleCloseMenu = () => {
        modalMenuMobile(false)
    }
    return (
        <>
            <div class="Content__mobileMenu">
            <div class="container">
                <div class="Content__mobileMenu--container">
                    <div class="Content__mobileMenu--currentLink">
                        <i onClick={goMenuBack} class="fas fa-chevron-right"></i>
                        <h4>CATEGORIAS</h4>
                    </div>
                    <div class="Content__mobileMenu--mainNavs">
                        <div class="Content__mobileMenu--mainNavs-nav bodyActive2" linkType="linkCategories">
                            <ul class="categories">
                                <li onClick={(e)=>{menuClick('mundoGamer', e)}} class="mundoGamer" navFor="mundoGamer"><span>Gamer</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('component_pc', e)}} class="component_pc" navFor="component_pc"><span>Componentes</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('streaming', e)}} class="streaming" navFor="streaming"><span>Streaming</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('console_games', e)}} class="console_games" navFor="console_games"><span>Consolas</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('electronics', e)}} class="electronics" navFor="electronics"><span>Electronica</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('home_office', e)}} class="home_office" navFor="home_office"><span>Oficina y Hogar</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('setups', e)}} class="setups" navFor="setups"><span>Computadores</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('pasatiempos', e)}} class="pasatiempos" navFor="pasatiempos"><span>Pasatiempo</span> <i class="fas fa-chevron-right"></i></li>
                                <li onClick={(e)=>{menuClick('lego', e)}} class="lego" navFor="lego"><span>LEGO</span> <i class="fas fa-chevron-right"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div class="Content__mobileMenu--categoriesBody">
                    <div class="Content__menu--content-body" BodyName="mundoGamer">
                        <ul class="mundoGamerNav">
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/teclados-gamer"}>TECLADOS GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/mouse-gamer"}>MOUSE GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/audifonos-gamer"}>AUDIFONOS GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/mousepad-gamer"}>MOUSEPAD</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/escritorios-gamer"}>ESCRITORIOS GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/lentes-gaming"}>LENTES GAMING</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/sillas-gamer"}>SILLAS GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/kits-gamer"}>KITS GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/parlantes-gamer"}>PARLANTES</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/soporte-de-audifonos"}>SOPORTE DE AUDIFONOS</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/controles-y-volantes"}>CONTROLES Y VOLANTES</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/iluminacion-rgb"}>ILUMINACIÓN RGB</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/otros-accesorios"}>OTROS ACCESORIOS</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/monitores-gamer"}>MONITORES GAMER</Link></li>
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/membresias-online"}>MEMBRESIAS ONLINE</Link></li>
                        </ul>
                    </div>
                        <div class="Content__menu--content-body" BodyName="component_pc">
                            <ul class="component_pc_Nav">
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/procesadores"}>PROCESADORES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/tarjetas-de-video"}>TARJETAS DE VIDEO</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/placas-madres"}>PLACAS MADRE</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/fuentes-de-poder"}>FUENTES DE PODER</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/gabinetes"}>GABINETES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/memorias-ram"}>MEMORIAS RAM</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/pasta-termica"}>PASTA TÉRMICA</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/refrigeracion-liquida"}>REFRIGERACIÓN LIQUIDA</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/fans-y-controladores"}>FANS Y CONTROLADORES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/cooler-para-cpu"}>COOLER PARA CPU</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/accesorios"}>ACCESORIOS COMPONENTES</Link></li>
                                    <h4 class="listTitle"><Link onClick={handleCloseMenu} to={"/categoria-producto/almacenamiento"}>ALMACENAMIENTO</Link></h4>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/discos-m-2"}>DISCOS M.2</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/ssd-y-discos-duros"}>SSD Y DISCO DURO</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/discos-y-ssd-externos"}>DISCOS Y SSD EXTERNOS</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="streaming">
                            <ul class="streaming_Nav">
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/webcams-streaming"}>WEBCAMS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/microfonos"}>MICROFONOS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/iluminacion"}>ILUMINACIÓN</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/capturadoras"}>CAPTURADORAS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/stream-decks"}>STREAM DECKS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"categoria-producto/otros-accesorios-streaming"}>OTROS ACCESORIOS</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="console_games">
                            <ul class="console_games_Nav">
                                <h4 class="listTitle"><Link onClick={handleCloseMenu} to={"/categoria-producto/nintendo"}>NINTENDO</Link></h4>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/juegos"}>JUEGOS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/controles-y-cargadores"}>CONTROLES Y CARGADORES</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/fundas-y-estuches"}>FUNDAS Y ESTUCHES</Link></li>
                                <h4 class="listTitle"><Link onClick={handleCloseMenu} to={"/categoria-producto/playstation-5"}>PLAYSTATION 5</Link></h4>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/audifonos-ps5"}>AUDIFONOS PS5</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/controles-y-cargadores-ps5"}>CONTROLES Y CARGADORES</Link></li>
                                <h4 class="listTitle"><Link onClick={handleCloseMenu} to={"/categoria-producto/xbox"}>XBOX</Link></h4>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/audifonos-xbox/"}>AUDIFONOS XBOX</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/controles-y-cargadores-xbox/"}>CONTROLES Y CARGADORES XBOX</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="electronics">
                            <ul class="electronicsNav">
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/impresoras"}>IMPRESORAS</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/routers-y-switches"}>ROUTERS Y SWITCHES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/antenas-tv"}>ANTENAS TV</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/soportes-tv"}>SOPORTES TV</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/respaldo-energia"}>UPS RESPALDO ENERGIA</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/asistentes-virtuales"}>ASISTENTES VIRTUALES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/iluminacion-rgb-electronica"}>ILUMINACIÓN RGB</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/enchufes-inteligentes"}>ENCHUFES INTELIGENTES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/smartband"}>SMARTBAND</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/atriles-y-tripodes"}>ATRILES Y TRIPODES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/tarjetas-de-memoria-electronica"}>TARJETAS DE MEMORIA</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"categoria-producto/cables-y-adaptadores"}>CABLES Y ADAPTADORES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/pendrives"}>PENDRIVES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/accesorios-tablets"}>ACCESORIOS TABLETS</Link></li>
                                    <h4 class="listTitle"><Link onClick={handleCloseMenu} to={"/categoria-producto/notebooks"}>NOTEBOOKS</Link></h4>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/equipos"}>EQUIPOS</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/memorias-ram-notebooks"}>MEMORIAS RAM</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/bases-refrigeracion"}>BASES REFRIGERACIÓN</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/bolsos-y-maletines"}>BOLSOS Y MALETINES</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="home_office">
                            <ul class="home_officeNav">
                            <li><Link onClick={handleCloseMenu} to={"/categoria-producto/teclados-perifericos"}>TECLADOS</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/mouse-perifericos"}>MOUSE</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/webcam"}>WEBCAM</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/audifonos-audio"}>AUDIFONOS</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/tabletas-digitalizadoras"}>TABLETAS DIGITALIZADORAS</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/hubs-usb"}>HUBS USB</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/apoyo-lumbar/"}>APOYOS LUMBARES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/soporte-monitores"}>SOPORTE MONITORES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/parlantes-audio"}>PARLANTES</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/combo-teclado-y-mouse"}>COMBOS TECLADO Y MOUSE</Link></li>
                                    <li><Link onClick={handleCloseMenu} to={"/categoria-producto/monitores-oficina"}>MONITORES OFICINA</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="setups">
                            <ul class="setupsNav">
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/setups"}>SETUPS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/equipos"}>NOTEBOOKS</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/aio"}>ALL IN ONE</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="pasatiempos">
                            <ul class="pasatiemposNav">
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/figuras-coleccionables/"}>FIGURAS COLECCIONABLES</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/juegos-de-mesa/"}>JUEGOS DE MESA</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/accesorios-tcg/"}>ACCESORIOS TCG</Link></li>
                                <h4 class="listTitle">TCG</h4>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/pokemon-tcg/"}>POKEMON</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/optcg/"}>ONE PIECE</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/digimontcg/"}>DIGIMON</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/magic-tcg/"}>MAGIC: THE GATHERING</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/yugioh/"}>YU GI OH</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/dbtcg/"}>DRAGON BALL SUPER CARD GAME</Link></li>
                                <h4 class="listTitle">MITOS Y LEYENDAS</h4>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/pe-myl/"}>PRIMERA ERA</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/pb-myl/"}>PRIMER BLOQUE</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/sb-myl/"}>SEGUNDO BLOQUE</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/ne-myl/"}>NUEVA ERA</Link></li>
                            </ul>
                        </div>
                        <div class="Content__menu--content-body" BodyName="lego">
                            <ul class="legoNav" style={{textTransform: "uppercase"}}>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/architecture/"}>Architecture</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/botanical/"}>Botanical</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/city/"}>City</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/classic/"}>Classic</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/creator-3-en-1/"}>Creator 3 en 1</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/dc/"}>DC</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/disney/"}>Disney</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/harry-potter/"}>Harry Potter</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/jurassic-world/"}>Jurassic World</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/animal-crossing/"}>Animal Crossing</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/avatar/"}>Avatar</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/icons/"}>Icons</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/ideas/"}>Ideas</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/linea-lego/"}>Linea LEGO</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/super-mario/"}>Super Mario</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/marvel/"}>Marvel</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/minecraft/"}>Minecraft</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/minifiguras/"}>Minifiguras</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/ninjago/"}>NINJAGO</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/speed-champions/"}>Speed Champions</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/star-wars/"}>Star Wars</Link></li>
                                <li><Link onClick={handleCloseMenu} to={"/categoria-producto/technic/"}>Technic</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default MobileMenu;
