import React, { createContext } from 'react';
import useInitialState from '../hooks/useInitialState';
const MyContext = createContext();
const AppContext = ({ children }) => {
    const initialState = useInitialState();
  
    return (
      <MyContext.Provider value={initialState}>
        {children}
      </MyContext.Provider>
    );
  };

  export { MyContext, AppContext };